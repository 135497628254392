import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import Link from 'core/components/Link';
import { PageIndent } from 'core/components/Wrappers';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Festival from './icons/Festival.svg';
import Pervyye from './icons/Pervyye.svg';
import Molodezh from './icons/Molodezh.svg';
import Vk from './icons/Vk.svg';
import Tg from './icons/Tg.svg';
import CircleMob from './icons/CircleMob.svg';
import Circle from './icons/Circle.svg';


function Footer({ theme, isMobile }) {
  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .footer
          position relative
          margin 0 auto

        .info
          display grid
          grid-template-columns 2fr auto auto auto 1fr
          gap 40px
          align-items center
          padding 45px 0

          :global(.mobile) &
            justify-items center
            grid-template-columns 1fr
            padding 30px 20px 47px
            gap 25px
          
        .text
          :global(.mobile) &
            text-align center

        .circle
          display flex
          position absolute
          bottom 0
          right 0

          :global(.desktop) &
            @media (max-width: 1350px)
              display none

        .socials
          display grid
          grid-template-columns 40px 40px

          :global(.desktop) &
            position absolute
            bottom 60px
            right 125px
            gap 0 30px

            @media (max-width: 1350px)
              right 49px

          :global(.mobile) &
            margin-top 15px
            gap 0 60px

        .social
          display flex

        .vk
          &:hover
            :global([id*="$"])
              fill #2787f5

        .tg
          &:hover
            :global([id*="$"])
              fill #0088cc
      `}</style>
      <style jsx>{`
        .footer
          max-width ${theme.layout.maxWidth}

        .text
          font 500 14px/19px  ${theme.fonts.text}
          color ${theme.colors.primary}
          opacity 0.7
      `}</style>
    </scope>
  );

  const CircleIcon = isMobile ? CircleMob : Circle;
  const Socials = (
    <div className={scoped.wrapClassNames('socials')}>
      <Link
        to='https://vk.com/fest2024'
        type='secondary'
        className={scoped.wrapClassNames('vk social')}
      >
        <Vk />
      </Link>
      <Link
        to='https://t.me/fest2024'
        type='secondary'
        className={scoped.wrapClassNames('tg social')}
      >
        <Tg />
      </Link>
    </div>
  );

  return (
    <PageIndent top={0} bottom={0}>
      <div className={scoped.wrapClassNames('circle')}>
        <CircleIcon />
      </div>
      <div className={scoped.wrapClassNames('footer')}>
        <div className={scoped.wrapClassNames('info')}>
          <div className={scoped.wrapClassNames('text')}>
            &copy; АНО &quot;Дирекция Всемирного фестиваля молодёжи&quot;. &nbsp;
            {!isMobile && <br />}
            Все права защищены.
          </div>
          <Link to='https://fest2024.com/'>
            <Festival />
          </Link>
          <Link to='https://будьвдвижении.рф'>
            <Pervyye />
          </Link>
          <Link to='https://fadm.gov.ru/'>
            <Molodezh />
          </Link>
          <Mobile>{Socials}</Mobile>
        </div>
      </div>
      <Desktop>{Socials}</Desktop>
      <scoped.styles />
    </PageIndent>
  );
}

Footer.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(withBreakpoint(Footer));
